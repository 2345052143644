

.swiper-button-prev {
    color: white;
}

.swiper-button-next {
    color: white;
}

.swiper-pagination-bullet {
    background: #6a989f;
}
.swiper-pagination-bullet-active {
    background: white;
}

.user-owned-nft-pagination {
    background: #8bafb4;
    border-radius: 14px;
    width: fit-content;
}

.background-selector-pagination {
    margin-top: 16px;
    background: #8bafb4;
    border-radius: 14px;
    width: fit-content;
}